<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
      <div class="google-map-setting-wrapper">

        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
          <h4 class="">Google Map/List</h4>
        </div>

            <div class="accordion" id="googleMapAccordion">

              <div class="card">
                <div class="card-header" id="settingGoogleMapDistanceMatrixApiKeyHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingGoogleMapDistanceMatrixApiKeyCollapse" aria-expanded="false" aria-controls="collapseGoogleMapDistanceMatrixApiKey">

                    <span class="text-capitalize google-map-setting-title"> Google Map Distance Matrix Api Key</span>

                  </button>
                </div>
                <div id="settingGoogleMapDistanceMatrixApiKeyCollapse" class="collapse" aria-labelledby="settingGoogleMapDistanceMatrixApiKeyHeading" data-parent="#googleMapAccordion">
                  <div class="card-body">
                    <SettingGoogleMapDistanceMatrixApiKey :google-map-distance-matrix-api-key="this.googleMapDistanceMatrixApiKey"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingGoogleMapDistanceMatrixApiEndpointHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingGoogleMapDistanceMatrixApiEndpointCollapse" aria-expanded="false" aria-controls="collapseGoogleMapDistanceMatrixApiEndpoint">

                    <span class="text-capitalize google-map-setting-title"> Google Map Distance Matrix Api Endpoint</span>

                  </button>
                </div>
                <div id="settingGoogleMapDistanceMatrixApiEndpointCollapse" class="collapse" aria-labelledby="settingGoogleMapDistanceMatrixApiEndpointHeading" data-parent="#googleMapAccordion">
                  <div class="card-body">
                    <SettingGoogleMapDistanceMatrixApiEndpoint :google-map-distance-matrix-api-endpoint="this.googleMapDistanceMatrixApiEndpoint"/>
                  </div>
                </div>
              </div>

            </div>


      </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from "@/layouts/backEnd/AppLayout";
import {mapActions, mapGetters} from "vuex";
import SettingGoogleMapDistanceMatrixApiEndpoint from "@/views/backEnd/settings/googleMaps/includes/SettingGoogleMapDistanceMatrixApiEndpoint";
import SettingGoogleMapDistanceMatrixApiKey from "@/views/backEnd/settings/googleMaps/includes/SettingGoogleMapDistanceMatrixApiKey";

export default {
  name: "SettingGoogleMapList.vue",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
    SettingGoogleMapDistanceMatrixApiEndpoint,
    SettingGoogleMapDistanceMatrixApiKey,

  },
  data() {
    return {
      getSettingsParams: {
        type: ["google_map"],
        key: [
          "google_map_distance_matrix_api_key",
          "google_map_distance_matrix_api_endpoint",

        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      googleMapDistanceMatrixApiKey: 'appSettings/settingGoogleMapDistanceMatrixApiKey',
      googleMapDistanceMatrixApiEndpoint: 'appSettings/settingGoogleMapDistanceMatrixApiEndpoint',

    }),
  },
  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.google-map-setting-wrapper .accordion .card {
  overflow: visible !important;
}

.google-map-setting-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
